
import { defineComponent, ref, onMounted, onUpdated } from "vue";
import KTDatatable from "@/components/kt-datatable/KTDatatable.vue";
import ApiService from "@/core/services/ApiService";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";

export default defineComponent({
  name: "upload",
  setup() {
    const store = useStore();
    const logs = ref([]) as any;
    const data = ref([
      {
        id: "Emma Smith",
        path: "Active",
      },
    ]);
    const processFile = () => {
      const id = [] as any;
      const status = [1] as any;
      logs.value.forEach(function (value) {
        if (value.check) {
          id.push(value.code);
        }
      });
      const data = {
        ids: id,
      };
      ApiService.post("api/edifile/process", data);
    };
    const uploadFile = (event) => {
      store.commit("setLoadingStatus", true);
      const frm = new FormData();
      const files = [] as any;
      for (var i = 0; i < event.target.files.length; i++) {
        files.push(event.target.files[i]);
        frm.append("edifact[]", event.target.files[i]);
      }
      ApiService.postAttach("/api/edifile/upload", frm).then(function (
        response
      ) {
        response.data.forEach(function (value) {
          logs.value.push({
            check: false,
            code: value.id,
            color: "success",
            message: value.path,
            date: value.created_at,
          });
        });
        store.commit("setLoadingStatus", false);
      }).catch( (error) => {
            console.log(error)
            store.commit("setLoadingStatus", false);
          }
      );
    };
    const headerConfig = ref([
      {
        key: "checkbox",
        sortable: false,
      },
      {
        name: "id",
        key: "id",
        sortable: true,
      },
      {
        name: "Path",
        key: "path",
        sortable: true,
      },
    ]);

    onMounted(() => {
      console.clear();
      setCurrentPageBreadcrumbs("Upload file", [
        { route: "/maritimefiles", label: "maritime_files" },
      ]);
    });

    onUpdated(() => {
      console.clear();
    });

    return {
      logs,
      uploadFile,
      processFile,
      data,
      headerConfig,
    };
  },
});
